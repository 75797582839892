.link {
  margin-top: 1rem;
}

.rebates {
  display: inline-flex;
  width: 100%;
  text-align: center;
  justify-content: space-between;
}

.button {
  margin-top: 2rem;
  padding: 0.5rem 2rem 0.5rem 2rem;
}

.buttonText {
  padding: 0rem;
  flex-wrap: nowrap;
}

.bannerContain {
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  text-shadow:
    0 0 2px #333,
    0 0 2px #333,
    0 0 2px #333;
}

.bannerTextStack {
  max-width: 90vw;
  width: 100%;
}

.bannerGradient {
  position: absolute;
  width: 100%;
  height: 100%;
  background: linear-gradient(to left, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.95));
}

.bannerContent {
  z-index: 1;
  margin-top: auto;
}

.bannerTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.spacer {
  width: 100%;
  aspect-ratio: 320 / var(--m);
  overflow: hidden;
  animation-name: collapse;
  animation-delay: 4s;
  animation-fill-mode: forwards;
  animation-iteration-count: 1;
  animation-duration: 0.1s;
}

@keyframes collapse {
  from {
    aspect-ratio: initial;
  }

  to {
    aspect-ratio: auto;
  }
}

@media only screen and (min-width: 600px) {
  .spacer {
    aspect-ratio: 600 / var(--t);
  }
}

@media only screen and (min-width: 767px) {
  .bannerGradient {
    background: linear-gradient(
      to left,
      rgba(0, 0, 0, 0.1),
      rgba(0, 0, 0, 0.95)
    );
  }
  .bannerContain {
    height: 220px;
  }
}

@media only screen and (min-width: 1366px) {
  .spacer {
    aspect-ratio: 1366 / var(--d);
  }
  .bannerContain {
    height: 280px;
  }
}

.hideMobile {
  display: none;
}

.card {
  width: 100%;
  height: 350px;
}

.emailText {
  display: inline-flex;
  flex-wrap: wrap;
  width: 100%;
  align-content: center;
}

.option {
  position: relative;
  border-style: solid;
  border-width: 1px;
  box-sizing: border-box;
  width: 100%;
  display: flex;
  justify-content: center;
}

.optionImage {
  mix-blend-mode: multiply;
}

.emailSignup {
  display: block;
}

@media (min-width: 835px) {
  .option {
    position: relative;
    border-style: solid;
    border-width: 1px;
    box-sizing: border-box;
    width: 50%;
    display: flex;
    justify-content: center;
  }

  .optionImage {
    mix-blend-mode: multiply;
  }

  .emailSignup {
    display: flex;
    justify-content: space-between;
  }

  .hideMobile {
    display: block;
  }

  .card {
    display: flex;
    width: 50%;
    overflow: hidden;
    border-radius: 8px;
    margin-right: 0.5rem;
    margin-left: 0.5rem;
  }

  .socketImg {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .emailText {
    display: inline-flex;
    flex-wrap: wrap;
    width: 50%;
    align-content: center;
  }

  .rebateContent {
    height: 238px;
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    margin-left: 3rem;
  }
}
